<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="headerNotChrome header header-fixed"
    v-bind:class="headerClasses"
  >
    <img src="" alt="" />
    <div class="bar custom-zIndex">
            <!-- <span
              >Apology for the inconvenience, we are facing technical issues. We will be back shortly.</span
            > -->
            <div class="d-flex align-items-center justify-content-between h-main-head" :class="$route.name === 'dashboard' || $route.name === 'offers' ? '' : 'pt-2 pb-2'">
            <div>
            <span
              >Data is subject to change and these are unverified orders.</span
            >
            </div>
            <!-- <span
              >We're refreshing the conversions and we'll be back soon.</span
            > -->
            <div class="h-btn-div">
            <div class="export-btn">
              <button v-show="$route.name === 'dashboard' || $route.name === 'offers'" @click="exportCSV" ><span class="fa fa-download mr-2"></span>Export as CSV</button>
            </div>
            </div>
            </div>
          </div>
    <div class="d-flex align-items-stretch justify-content-between p-0"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
      <div class="d-none d-flex">
        <!-- <div class=""> -->
        <!--begin::Aside Toggle-->
        <!-- begin::Svg Icon | path:assets/media/svg/icons/Text/Align-left.svg -->
        <!-- <button
          class="btn btn-icon aside-toggle ml-n3 mr-10"
          id="kt_aside_desktop_toggle"
          ref="kt_aside_desktop_toggle"
        >
          <span class="svg-icon svg-icon-xxl svg-icon-dark-75">
            <inline-svg src="media/svg/icons/Text/Align-left.svg" />
          </span>
        </button> -->
        <!--end::Svg Icon-->
        <!--end::Aside Toggle-->
        <!-- <router-link to="/">
            <img
              alt="Logo"
              :src="layoutConfig('self.logo.default')"
              class="logo-sticky max-h-35px"
            />
            </router-link> -->
        <div class="header-logo">
          <router-link to="/">
            <img
              alt="Logo"
              :src="layoutConfig('self.logo.default')"
              class="logo-sticky max-h-35px"
            />
          </router-link>
          <div class="mt-3" style="width: inherit">
            <span class="user-name" v-show="$route.name === 'dashboard'">Welcome, {{ user }}</span>
            <span class="filter custom-zIndex-1">
              <span
                class="date"
                v-if="$_filteration.start_date || $_filteration.end_date"
              >
                <span class="fa fa-calendar"></span>
                <span
                  v-if="$_filteration.start_date === $_filteration.end_date"
                >
                  {{ $_filteration.start_date }}
                </span>
                <span v-else>
                  {{ $_filteration.start_date }} - {{ $_filteration.end_date }}
                </span>
              </span>
              <span class="fltr">
                <!-- <button class="fa fa-tasks" data-toggle="modal" data-target="#filters_modal"></button> -->
                <span class="my-2" v-show="$route.name === 'dashboard'">
                  <span v-b-modal.filter_modal class="fa fa-tasks"><span>Filter</span></span>

                </span>
                <b-dropdown
                  v-show="$route.name === 'offers'"
                  size="sm"
                  variant="light"
                  toggle-class="btn btn-transparent-white btn-sm px-3 offer-sort-btn"
                  no-caret
                  right
                  no-flip
                  html="<span>&#8652;</span> Sort"
                >
                  <div class="navi navi-hover min-w-md-250px offer-container">
                    <b-dropdown-item tag="div" class="navi-item">
                      <div class="navi-link offer-link sort-offer-link" @click="handleOfferFilter('alphabet')">                        
                        <span class="navi-text offer-text">
                          Alphabetical
                        </span>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item tag="div" class="navi-item">
                      <div class="navi-link offer-link sort-offer-link" @click="handleOfferFilter('status')">                        
                        <span class="navi-text offer-text">
                          Offer Status
                        </span>
                      </div>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item tag="div" class="navi-item">
                      <div class="navi-link offer-link sort-offer-link" @click="handleOfferFilter('coupon_offering')">                        
                        <span class="navi-text offer-text">
                          Coupon
                        </span>
                      </div>
                    </b-dropdown-item> -->
                  </div>
                </b-dropdown>
                <b-dropdown
                  size="sm"
                  variant="light"
                  class="btn-profile-dropdown"
                  toggle-class="btn btn-transparent-white btn-sm px-3 offer-sort-btn h-user-container"
                  no-caret
                  right
                  no-flip
                >
                <template #button-content>                  
                  <div v-if="notificationList && notificationList.length > 0" class="n-dot"></div>           
                  <div class="h-user-icon"><i class="far fa-user p-0"></i></div>
                </template>
                  <div class="navi navi-hover min-w-md-250px offer-container">
                    <b-dropdown-item tag="div" class="navi-item" to="/profile">
                      <div class="navi-link offer-link">                        
                        <span class="navi-text offer-text">
                          My Profile
                        </span>
                      </div>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item tag="div" class="navi-item" to="/notification">
                      <div class="navi-link offer-link">                        
                        <span class="navi-text offer-text d-flex">
                          Notifications <p class="h-notify-count mb-0 ml-2">{{ notificationList && notificationList.length }}</p>
                        </span>
                      </div>
                    </b-dropdown-item> -->
                    <b-dropdown-item tag="div" class="navi-item">
                      <div class="navi-link offer-link" @click="logout">                        
                        <span class="navi-text offer-text">
                          Sign Out
                        </span>
                      </div>
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
               <!-- <router-link to="/profile" class="user-icon"><i class="far fa-user"></i></router-link> -->
                 <!-- <router-link to="/profile" class="user-icon"><i class="far fa-user"></i></router-link> -->
                <!-- <v-btn
                  plain
                  class="logout ml-12 mr-3 text-capitalize"
                  @click="logout"
                  >Log Out</v-btn
                > -->
                <!--  -->

                <!--  -->
              </span>
            </span>
          </div>
          
        </div>
      </div>
      <!-- <KTTopbar></KTTopbar> -->
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import Swal from "sweetalert2";
import ApiService from "../../../core/services/api.service";

export default {
  name: "KTHeader",
  data() {
    return {
      user: null,
      notificationList: [],
      sortHtml: <img src='./imgs/arrow.png'/>
    };
  },
  components: {
    KTTopbar,
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    this.user = window.localStorage.getItem("user");

    this.affiliate_id = window.localStorage.getItem("affiliate_id");
    // this.getNotificationLength();
    // this.$eventBus.$on("setNotification", () => {
    //   this.getNotificationLength();         
    // });   
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/login-bg.svg";
    },

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    },
  },
  methods: {
    getNotificationLength(){
      let data = { affiliate_id: this.affiliate_id };
      try{
        ApiService.post("notifications",data).then((response) => {
          this.notificationList = response.data.data.filter((item) => item.is_seen == false);
        }).catch(error => {
              if(error.response.status == 400){
                localStorage.clear();
                localStorage.removeItem("affiliate_id");
                window.location.reload();
                // Swal.fire({
                //   imageUrl: "media/images/logout-emoji.png",
                //   imageWidth: 165,
                //   imageHeight: 123,
                //   title: "Your Session is expired.",
                //   timer: 1500,
                //   timerProgressBar: true,
                //   showConfirmButton: false,
                // })
                // setTimeout(() => {
                //   window.location.reload();
                // }, 1500);
              }
              throw new Error(`[KT] ApiService ${error}`);
            });
      }catch(error){
        console.log("Error",error);
      }
    },
    exportCSV() {
      this.$eventBus.$emit('downloadFile'); // 'this' is needed.
    },
    handleOfferFilter(data){
      this.$eventBus.$emit('offerFilter',data); 
    },
    logout() {
      Swal.fire({
          text: "Do you Really want to log out ?",
          imageUrl: "media/images/logout-emoji.png",
          imageWidth: 165,
          imageHeight: 123,
          imageAlt: "Log Out",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#e3e8ef",
          cancelButtonColor: "#FF6633",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
             localStorage.clear();
             localStorage.removeItem("affiliate_id");
             window.location.reload();
          }else{
            Swal.close();
          }
      }); 
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-zIndex{
  z-index: 1 !important;
}
.custom-zIndex-1{
  z-index: 1 !important;
}
.n-dot{
    position: absolute;
    background: red;
    width: 7px;
    height: 7px;
    right: 10px;
    border-radius: 5px;
}
.h-user-icon{
  width: 30px;
  height: 30px;
  background: #000;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.h-user-icon .fa-user{
  color: #fff !important;
}
.headerNotChrome {
  // background: #FF6633;
  background-image: url("./imgs/dash-header.png");
  z-index: unset !important;
  background-position:center;
  background-repeat: repeat;
}
.header {
  // background:#151A4B
  // background-image: url("./imgs/header.png");

  //  linear-gradient(
  //   to bottom left,
  //   rgba(21, 26, 75, 1),
  //   rgba(23, 33, 134, 1),
  //   rgba(21, 26, 75, 1),
  //   rgba(21, 26, 75, 1)
  // ) !important;
}
// #151a4b,
// #172186,
// #151a4b,
// #151a4b
.h-main-head{
  max-width: 1185px;
  margin: auto;
}
.h-btn-div .export-btn button{
  background: linear-gradient(#e2e2e7, #ffffff);
    border: none;
    border-radius: 3px;
    font-size: 13px;
    color: #000000;
    padding: 5px 10px;
}
.logout {
  background-color: black;
  color: white !important;
}
.header-fixed .header {
  position: absolute !important;
  z-index: -1;
  // background-image: url("./imgs/header.png");
  // background-size: 100%;
}
@media (min-width: 900px) {
  .header-fixed .header {
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
    height: 210px !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
.header-logo {
  position: absolute;
  width: 100%;
  top:50px;
}
.user-name {
  color: #000;
  font-size: 3rem;
  font-weight: 700;
}
.filter {
  color: #000;
  font-size: 1rem;
  float: right;
  margin-top: 2rem;
}
.date {
  margin-right: 1rem;
  span {
    margin-right: 1rem;
  }
}
.fltr {
  margin-left: 1rem;
  z-index: 9999 !important;
  span {
    margin-left: 1rem;
  }
}
.bar {
  width: 100%;
  color: white;
  background: black;
  padding: 0px 10px;
  // padding: 5px 10px;
   position: absolute;
    top: 0;
    z-index: 11;
}

.bar > span {
    max-width: 1185px;
    display: block;
    margin: 0 auto;
}

.filter {
    position: relative;
    top: -56px;
}

.header-fixed .header {
    height: 270px !important;
}

.header-logo span.user-name {
    font-size: 30px;
}

.header-logo > div {
    margin-top: 0 !important;
    width: 100% !important;
}
.fltr a.user-icon {
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
}

.fltr a.user-icon i {
    color: #000;
}

.filter span.fltr {
    display: flex;
    align-items: center;
}
.col-xl-12.col-md-12.col-sm-12.mb-3.ddd {
    padding-bottom: 12px !important;
}

.header-fixed .header .bar + div {
    max-width: 1185px;
    position: relative;
}
.filter button.logout {
    order: 1;
    background: transparent !important;
    opacity: 1;
    box-shadow: none;
    font-size: 13px;
    margin: 0 !important;
    padding: 0 !important;
    height: auto;
}

// .fltr a.user-icon {
//     order: 3;
// }
.fltr .btn-profile-dropdown{
    order: 3;
}

.fltr span {
    order: 1;
    margin-left: 10px !important;
}
.header-fixed .header {
    height: 310px !important;
}

</style>
