<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <div class="bar">
      <!-- <span>We're refreshing the conversions and we'll be back soon.</span> -->
      <span>Data is subject to change and these are unverified orders.</span>
      <!-- <span>Apology for the inconvenience, we are facing technical issues. We will be back shortly.</span> -->
    </div>

    <div class="box custom-box">
      <div class="mobile-logo-wrapepr">
        <div class="logo">
          <router-link to="/">
            <img
              alt="Logo"
              :src="layoutConfig('self.logo.default')"
              class="logo-sticky max-h-35px"
            />
          </router-link>
        </div>
        <div class="filter-wrapper">
          <button
            v-show="$route.name === 'dashboard' || 'offers'"
            class="hm-export-btn"
            @click="exportCSV"
          >
            <img alt="csv" class="hm-export-img" src="./imgs/Union.svg" />
          </button>
          <!-- <v-btn plain class="logout ml-12 mr-3 text-capitalize" @click="logout">Log Out</v-btn> -->
          <span class="filter" v-show="$route.name === 'dashboard'">
            <span class="my-2">
              <span @click="filerModal" class="fa fa-tasks"></span>
              <span class="">Filter</span>
            </span>
          </span>
          <b-dropdown
            size="sm"
            variant="light"
            class="btn-mobile-profile"
            toggle-class="btn btn-transparent-white btn-sm px-3 offer-sort-btn h-user-container"
            no-caret
            right
            no-flip
          >
            <template #button-content>
              <div
                v-if="notificationList && notificationList.length > 0"
                class="n-dot"
              ></div>
              <div class="h-user-icon"><i class="far fa-user p-0"></i></div>
            </template>
            <div class="navi navi-hover min-w-md-250px offer-container">
              <b-dropdown-item tag="div" class="navi-item" to="/profile">
                <div class="navi-link offer-link">
                  <span class="navi-text offer-text mr-0"> My Profile </span>
                </div>
              </b-dropdown-item>
              <!-- <b-dropdown-item tag="div" class="navi-item" to="/notification">
                <div class="navi-link offer-link">
                  <span class="navi-text offer-text d-flex">
                    Notifications
                    <p class="h-notify-count mb-0 ml-2">
                      {{ notificationList && notificationList.length }}
                    </p>
                  </span>
                </div>
              </b-dropdown-item> -->
              <b-dropdown-item tag="div" class="navi-item">
                <div class="navi-link offer-link" @click="logout">
                  <span class="navi-text offer-text"> Sign Out </span>
                </div>
              </b-dropdown-item>
            </div>
          </b-dropdown>
          <b-dropdown
            v-show="$route.name === 'offers'"
            size="sm"
            variant="light"
            class="btn-mobile-profile"
            toggle-class="btn btn-transparent-white btn-sm px-3 offer-sort-btn"
            no-caret
            right
            no-flip
            html="<span>&#8652;</span> Sort"
          >
            <div class="navi navi-hover min-w-md-250px offer-container">
              <b-dropdown-item tag="div" class="navi-item">
                <div
                  class="navi-link offer-link sort-offer-link"
                  @click="handleOfferFilter('alphabet')"
                >
                  <span class="navi-text offer-text"> Alphabetical </span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item tag="div" class="navi-item">
                <div
                  class="navi-link offer-link sort-offer-link"
                  @click="handleOfferFilter('status')"
                >
                  <span class="navi-text offer-text"> Offer Status </span>
                </div>
              </b-dropdown-item>
              <!-- <b-dropdown-item tag="div" class="navi-item">
                      <div class="navi-link offer-link sort-offer-link" @click="handleOfferFilter('coupon_offering')">                        
                        <span class="navi-text offer-text">
                          Coupon
                        </span>
                      </div>
                    </b-dropdown-item> -->
            </div>
          </b-dropdown>
          <!-- <router-link to="/profile" class="user-icon"><i class="far fa-user"></i></router-link> -->
        </div>
      </div>
      <div class="performance">
        <h3>Performance Overview</h3>
        <span>Last Refreshed 2 mins ago<i class="fas fa-redo-alt"></i></span>
      </div>
    </div>
    <!--begin::Logo-->
    <!-- <a href="/">
      <img alt="Logo" :src="headerLogo" class="logo-default max-h-30px" />
    </a> -->
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <!-- <div class="d-flex align-items-center"> -->
    <!--begin::Aside Mobile Toggle-->
    <!-- <button
        v-if="asideEnabled" 
        
        class="btn p-0 burger-icon rounded-0 burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button> -->
    <!--end::Aside Mobile Toggle-->
    <!--begin::Header Menu Mobile Toggle-->
    <!-- <button
        class="btn p-0 burger-icon ml-4"
        id="kt_aside_tablet_and_mobile_toggle"
        ref="kt_aside_tablet_and_mobile_toggle"
      >
        <span>
          <inline-svg src="media/svg/icons/Text/Align-left.svg" />
        </span>
      </button> -->
    <!--end::Header Menu Mobile Toggle-->
    <!--begin::Topbar Mobile Toggle-->
    <!-- <button
        class="btn btn-hover-text-primary p-0 ml-3"
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
      >
        <span class="svg-icon svg-icon-xl"> -->
    <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
    <!-- <inline-svg class="svg-icon" src="media/svg/icons/General/User.svg" /> -->
    <!--end::Svg Icon-->
    <!-- </span>
      </button> -->
    <!--end::Topbar Mobile Toggle-->
    <!-- </div> -->
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import Swal from "sweetalert2";
import ApiService from "../../../core/services/api.service";

export default {
  name: "KTHeaderMobile",

  components: {},
  data() {
    return {
      user: "",
      notificationList: [],
    };
  },
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
    this.user = window.localStorage.getItem("user");
    this.affiliate_id = window.localStorage.getItem("affiliate_id");
    // this.getNotificationLength();
    // this.$eventBus.$on("setNotification", () => {
    //   this.getNotificationLength();
    // });
  },
  methods: {
    getNotificationLength() {
      let data = { affiliate_id: this.affiliate_id };
      ApiService.post("notifications", data).then((response) => {
        this.notificationList = response.data.data.filter(
          (item) => item.is_seen == false
        );
      }).catch(error => {
              if(error.response.status == 400){
                localStorage.clear();
                localStorage.removeItem("affiliate_id");
                window.location.reload();
                // Swal.fire({
                //   imageUrl: "media/images/logout-emoji.png",
                //   imageWidth: 165,
                //   imageHeight: 123,
                //   title: "Your Session is expired.",
                //   timer: 1500,
                //   timerProgressBar: true,
                //   showConfirmButton: false,
                // })
                // setTimeout(() => {
                //   window.location.reload();
                // }, 1500);
              }
              throw new Error(`[KT] ApiService ${error}`);
            });
    },
    handleOfferFilter(data) {
      this.$eventBus.$emit("offerFilter", data);
    },
    exportCSV() {
      this.$eventBus.$emit("downloadFile"); // 'this' is needed.
    },
    filerModal() {
      this.$eventBus.$emit("filerModal");
    },
    logout() {
      Swal.fire({
        text: "Do you Really want to log out ?",
        imageUrl: "media/images/logout-emoji.png",
        imageWidth: 165,
        imageHeight: 123,
        imageAlt: "Log Out",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#e3e8ef",
        cancelButtonColor: "#FF6633",
        reverseButtons: true,
        customClass: {
          popup: "logout-popup-class",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          localStorage.removeItem("affiliate_id");
          window.location.reload();
        } else {
          Swal.close();
        }
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.sticky");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
  },
};
</script>
<style lang="scss">
.logout-popup-class {
  z-index: 999999999999999 !important;
}
.custom-box {
  margin-top: -200px;
  position: unset !important;
}
</style>
<style lang="scss" scoped>
.n-dot {
  position: absolute;
  background: red;
  width: 7px;
  height: 7px;
  right: 10px;
  border-radius: 5px;
}
.h-user-icon {
  width: 30px;
  height: 30px;
  background: #000;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.h-user-icon .fa-user {
  color: #fff !important;
}
.header-mobile {
  // background: #FF6633;
  // background: url('./imgs/header-mobile.png');
  background-image: url("./imgs/header-login.png");
}
.logout {
  background-color: black;
  color: white !important;
}
.header-mobile .header {
  position: absolute !important;
  height: 40%;
}
.hm-export-btn {
  order: 4;
}
.hm-export-btn .hm-export-img {
  width: 22px;
  margin-right: 5px;
}
@media (max-width: 1200px) {
  .header-mobile-fixed .header-mobile {
    position: static;
    top: 0;
    right: 0;
    left: 0;
    height: 200px;
    padding: 0;
    z-index: unset !important;
  }
}

.bar {
  position: absolute;
  top: 0;
  color: white;
  background: black;
  width: 100% !important;
  padding: 0.5rem;
}
.box {
  color: white;
  position: absolute;
  top: 5%;
  width: 100%;
  padding: 1rem;
  .user-name {
    font-size: 5vh;
    font-weight: 600;
  }
}
.date {
  padding-right: 0.5rem;
}
.filter {
  float: right;
  span {
    padding-left: 0.5rem;
  }
}

button.swal2-confirm.swal2-styled {
  color: #FF6633 !important;
}
.mobile-logo-wrapepr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mobile-logo-wrapepr img {
  width: 100px;
}

.mobile-logo-wrapepr .filter span.my-2 {
  margin: 0 !important;
}

.mobile-logo-wrapepr span.filter {
  float: none;
  order: 2;
  margin-right: 15px;
  margin-left: 5px;
}

.mobile-logo-wrapepr .filter span.my-2 {
  padding-left: 0;
}

.filter-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.filter-wrapper a.user-icon {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;
}

.filter-wrapper a.user-icon i {
  color: #000;
}

.mobile-logo-wrapepr .filter span.my-2 span:last-child {
  display: none;
}

.mobile-logo-wrapepr .filter span.my-2 span.fa.fa-tasks {
  font-size: 20px;
}
.performance{  
  color: #000;
}
.performance span {
  font-size: 10px;
  color: #000;
}

.performance span i {
  color: #000;
  font-size: 11px;
  margin-left: 5px;
  transform: rotateY(160deg) rotate(12deg);
}

.performance h3 {
  margin-bottom: 0;
}
.header-mobile-fixed .header-mobile {
  height: 380px !important;
}
.filter-wrapper > button.logout {
  background: transparent !important;
  opacity: 1;
  box-shadow: none;
  font-size: 13px;
  margin: 0 !important;
  padding: 0 !important;
  order: 3;
  margin-right: 0 !important;
}
.filter .fa.fa-tasks{
  color: #000;
}
</style>